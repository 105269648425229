////select

import React, { Component } from "react"
import createFilterOptions from "react-select-fast-filter-options"
import Select from "react-select"
export default class SelectCustom extends Component {
  constructor() {
    super()
    this.teste = this.teste.bind(this)
    this.state = {}
  }
  shouldComponentUpdate(nextProps, nextState) {
    var shouldUpdate = false

    Object.keys(nextProps).forEach(key => {
      var currentProp = this.props[key]
      var nextProp = nextProps[key]
      if (shouldUpdate === false) {
        shouldUpdate = !(nextProp === currentProp)
      }
    })
    return shouldUpdate || this.state.inputValue !== nextState.inputValue
  }

  teste(a, b) {
    if (b.action !== "set-value") {
      this.setState({
        inputValue: a
      })
    }
  }

  render() {
    var options = this.props.options
    var filterOptions = createFilterOptions({ options })
    return (
      <div className="form-group">
        <label className="form-control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <Select
          className={`form-control form-control-alternative form-select ${
            this.props.error ? "has-danger" : ""
            }`}
          inputValue={this.state.inputValue}
          closeMenuOnSelect={!this.props.isMulti}
          onInputChange={this.teste}
          isDisabled={this.props.disabled}
          placeholder={this.props.placeholder}
          noResultsText="Nenhum resultado"
          filterOptions={filterOptions}
          options={options}
          value={this.props.value}
          isMulti={this.props.isMulti || false}
          isSearchable={this.props.isSearchable || true}
          onChange={selected =>
            this.props.onChange({
              target: { value: selected, name: this.props.name }
            })
          }
          isClearable
        />

        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}
