import React, { Component } from "react"
import { LayoutWrapper } from "./components"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom"
import {
  Login,
  Dashboard,
  Origens,
  OrigemForm,
  NotFound,
  Fontes,
  Especies,
  FonteForm,
  EspecieForm,
  TiposDeMovimento,
  TipoForm,
  Transferencias,
  TransferenciaForm,
  Movimentos,
  MovimentoForm,
  ResumoForm,
  TransferenciasReportForm,
  MovimentoGeralForm,
  MovimentoGeralDeCaixaForm,
  PagamentoPorPeriodoForm,
  ResumoDeContratosForm,
  DataDeBloqueioForm
} from "./views"
import MovimentoApagarForm from "./views/forms/MovimentoApagarForm"


function loggedIn() {
  if (!localStorage.token) {
    return false
  }
  return localStorage.token !== "null" && localStorage.token !== ""
}

const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            loggedIn() ? (
                <Redirect
                    to={{
                      pathname: "/movimentos",
                      state: { from: props.location }
                    }}
                />
            ) : (
                <Component {...props} />
            )
        }
    />
)

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            loggedIn() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: props.location }
                    }}
                />
            )
        }
    />
)
const RedirectToLogin = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
            />
        )}
    />
)
class Routes extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <LoginRoute exact path="/login" component={Login} />
            <PrivateRoute
                exact
                path="/dashboard"
                component={LayoutWrapper(Dashboard)}
            />
            <PrivateRoute
                exact
                path="/origens"
                component={LayoutWrapper(Origens)}
            />
            <PrivateRoute
                exact
                path="/movimentos"
                component={LayoutWrapper(Movimentos)}
            />
            <PrivateRoute
                exact
                path="/fontes"
                component={LayoutWrapper(Fontes)}
            />
            <PrivateRoute
                exact
                path="/especies"
                component={LayoutWrapper(Especies)}
            />
            <PrivateRoute
                exact
                path="/transferencias"
                component={LayoutWrapper(Transferencias)}
            />
            <PrivateRoute
                exact
                path="/tipos"
                component={LayoutWrapper(TiposDeMovimento)}
            />
            <PrivateRoute
                exact
                path="/origens/create"
                component={LayoutWrapper(OrigemForm)}
            />
            <PrivateRoute
                exact
                path="/origens/edit/:id"
                component={LayoutWrapper(OrigemForm)}
            />
            <PrivateRoute
                exact
                path="/fontes/create"
                component={LayoutWrapper(FonteForm)}
            />
            <PrivateRoute
                exact
                path="/fontes/edit/:id"
                component={LayoutWrapper(FonteForm)}
            />
            <PrivateRoute
                exact
                path="/especies/create"
                component={LayoutWrapper(EspecieForm)}
            />
            <PrivateRoute
                exact
                path="/especies/edit/:id"
                component={LayoutWrapper(EspecieForm)}
            />
            <PrivateRoute
                exact
                path="/tipos/create"
                component={LayoutWrapper(TipoForm)}
            />
            <PrivateRoute
                exact
                path="/tipos/edit/:id"
                component={LayoutWrapper(TipoForm)}
            />
            <PrivateRoute
                exact
                path="/transferencias/create"
                component={LayoutWrapper(TransferenciaForm)}
            />
            <PrivateRoute
                exact
                path="/transferencias/edit/:id"
                component={LayoutWrapper(TransferenciaForm)}
            />
            <PrivateRoute
                exact
                path="/movimentos/create"
                component={LayoutWrapper(MovimentoForm)}
            />
            <PrivateRoute
                exact
                path="/movimentos/edit/:id"
                component={LayoutWrapper(MovimentoForm)}
            />
            <PrivateRoute
                exact
                path="/resumoGeral"
                component={LayoutWrapper(ResumoForm)}
            />
            <PrivateRoute
                exact
                path="/transferenciasReport"
                component={LayoutWrapper(TransferenciasReportForm)}
            />
            <PrivateRoute
                exact
                path="/movimentoGeral"
                component={LayoutWrapper(MovimentoGeralForm)}
            />
            <PrivateRoute
                exact
                path="/resumoDeContratos"
                component={LayoutWrapper(ResumoDeContratosForm)}
            />
            <PrivateRoute
                exact
                path="/movimentoCaixaGeral"
                component={LayoutWrapper(MovimentoGeralDeCaixaForm)}
            />
            <PrivateRoute
                exact
                path="/pagamentosPeriodo"
                component={LayoutWrapper(PagamentoPorPeriodoForm)}
            />
            <PrivateRoute
                exact
                path="/datadebloqueio"
                component={LayoutWrapper(DataDeBloqueioForm)}
            />
            <PrivateRoute
                exact
                path="/movimentoAPagar"
                component={LayoutWrapper(MovimentoApagarForm)}
            />
            {/*Remove em caso de uma landpage ser adicionada*/}
            <RedirectToLogin exact path="/" />
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
    )
  }
}

export default Routes
